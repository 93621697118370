* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.disabled {
  display: none;
}
